// ProtectedRoute.js

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { arePermissionsAllowed } from "./permissionChecker"; // Adjust the import path as needed
import useAuth from "./../core/hooks/useAuth"; // Adjust the import path as needed

const ProtectedRoute = ({ allowedPermission }) => {
  const { auth } = useAuth(); // Assuming useAuth provides auth object with accessToken and permissions

  const isUserAuthenticated = !!auth.accessToken; // Check if user is authenticated
  const userPermissions = auth.permissions || {};

  if (!isUserAuthenticated) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/signin" replace />;
  }

  if (!arePermissionsAllowed(allowedPermission, userPermissions)) {
    // Redirect to unauthorized page if user does not have required permissions
    return <Navigate to="/unauthorized" replace />;
  }

  // Render children if authenticated and permissions are allowed
  return <Outlet />;
};

ProtectedRoute.propTypes = {
  allowedPermission: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
