import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { z } from "zod";
import useChangePassword from "../../../core/hooks/useChangePassword";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import TextInput from "../../../core/form-components/TextInput";
import Button from "../../../core/form-components/Button";
import PageLoader from "../../../core/loader/pageloader";
import Alert from "../../../core/alert/Alert";
import useAuth from "./../../../core/hooks/useAuth";

const schema = z.object({
  currentPassword: z
    .string({
      invalid_type_error: "Current password is required.",
    })
    .nonempty("Current password is required"),
  newPassword: z
    .string({
      invalid_type_error: "New password is required.",
    })
    .nonempty("New password is required"),
});

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const route = all_routes;

  const changePassword = useChangePassword();
  const { auth } = useAuth();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const request = {
      username: auth.username,
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };
    changePassword.mutate(request, {
      onSuccess: () => {
        navigate(route.logout, {
          state: { fromChangeReset: true },
        });
      },
    });
  };

  return (
    <>
      {changePassword.isPending && <PageLoader />}
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper login-new">
            <div className="container">
              <div className="login-content user-login">
                <div className="login-logo">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" alt />
                  </Link>
                </div>
                <form action="signin-3" onSubmit={handleSubmit(onSubmit)}>
                  <div className="login-userset">
                    <div className="login-userheading">
                      <h3>Forgot password?</h3>
                      <h4>
                        Enter your current password and the new one you want to
                        reset to
                      </h4>
                      {changePassword.error && (
                        <Alert
                          label="Failed to change password. Please try again"
                          theme="danger"
                        />
                      )}
                    </div>
                    <div className="form-login">
                      <div className="form-addons">
                        <TextInput
                          label="Current Password"
                          register={register("currentPassword")}
                          errors={errors.currentPassword}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                      <div className="form-addons">
                        <TextInput
                          label="New Password"
                          register={register("newPassword")}
                          errors={errors.newPassword}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <div className="d-grid gap-2 mb-4">
                        <Button color="secondary" label="Change Password" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © 2023 DreamsPOS. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
