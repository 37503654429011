import React from "react";
const PageLoader = () => {
  return (
    <div id="global-loader">
      <div className="whirly-loader"> </div>
    </div>
  );
};

export default PageLoader;
