import { useMutation } from "@tanstack/react-query";
import getEnvVariable from "../../getEnvVariable";
import usePublicAPIClient from "../services/usePublicAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useRefreshToken = () => {
  const { create } = usePublicAPIClient(baseUrl, "/api/v1/refresh-token", true);
  return useMutation({
    mutationFn: (data) => create(data),
  });
};

export default useRefreshToken;
