import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useDeleteExpenseCategory = () => {
  const { deleteById } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/expense-categories",
    true
  );
  return useMutation({
    mutationFn: ({ id, userId }) => deleteById(id, { params: { userId } }),
  });
};

export default useDeleteExpenseCategory;
