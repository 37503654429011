import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";
import { EXPENSES_QUERY_KEY } from "../queries/queryKeys";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetExpense = () => {
  const { getAll } = usePrivateAPIClient(baseUrl, "/api/v1/expenses", true);
  return useQuery({
    queryKey: [EXPENSES_QUERY_KEY],
    queryFn: () => getAll(),
  });
};

export default useGetExpense;
