import React, { useState } from "react";
import { PlusCircle, X } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
export default function FileUploadInput({
  register,
  previewURLs,
  setPreviewURLs,
  errors,
  maxFiles = 1,
}) {
  const { ref: registerRef, ...rest } = register("images", {
    validate: (files) => files.length <= maxFiles,
  });

  const [fileNames, setFileNames] = useState([]);
  const handleImageChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files) {
      const newPreviewURLs = [];
      const newFileNames = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          newPreviewURLs.push(reader.result);
          if (newPreviewURLs.length === files.length) {
            setPreviewURLs([...previewURLs, ...newPreviewURLs]);
            setFileNames([...fileNames, ...newFileNames]);
          }
        };
        reader.readAsDataURL(file);
        newFileNames.push(file.name);
      }
    }
  };
  return (
    <>
      <div className="add-choosen">
        <div className="input-blocks">
          <div className="image-upload">
            <input
              type="file"
              {...rest}
              className="multi file-upload-input with-preview"
              onChange={handleImageChange}
              ref={(e) => {
                registerRef(e);
              }}
            />
            <div className="image-uploads">
              <PlusCircle className="plus-down-add me-0" />
              <h4>Add Images</h4>
            </div>
          </div>
        </div>
        {previewURLs.map((previewURL, index) => (
          <div className="phone-img" key={index}>
            <img src={previewURL} alt={`Preview of ${fileNames[index]}`} />
            <Link to="#">
              <X
                className="x-square-add remove-product"
                onClick={() => {
                  const updatedPreviewURLs = [...previewURLs];
                  updatedPreviewURLs.splice(index, 1);
                  setPreviewURLs(updatedPreviewURLs);
                }}
              />
            </Link>
          </div>
        ))}
      </div>
      {errors && <p className="text-danger">{errors.message?.toString()}</p>}
    </>
  );
}
FileUploadInput.propTypes = {
  register: PropTypes.any.isRequired,
  previewURLs: PropTypes.array.isRequired,
  setPreviewURLs: PropTypes.func.isRequired,
  errors: PropTypes.string,
  maxFiles: PropTypes.number,
};
