import axios from "axios";

const usePublicAPIClient = (baseURL, endpoint, isWithCredential) => {
  const axiosPublic = axios.create({
    baseURL,
    withCredentials: isWithCredential,
  });

  const getAll = async (config = {}) => {
    const response = await axiosPublic.get(endpoint, config);
    return response.data;
  };

  const getById = async (id, config = {}) => {
    const response = await axiosPublic.get(`${endpoint}/${id}`, config);
    return response.data;
  };

  const deleteById = async (id, config = {}) => {
    const response = await axiosPublic.delete(`${endpoint}/${id}`, config);
    return response.data;
  };

  const create = async (
    data = {},
    config = { headers: { "Content-Type": "application/json" } }
  ) => {
    const response = await axiosPublic.post(endpoint, data, config);
    return response.data;
  };

  const update = async (
    id,
    data,
    config = { headers: { "Content-Type": "application/json" } }
  ) => {
    const response = await axiosPublic.put(`${endpoint}/${id}`, data, config);
    return response.data;
  };

  return { getAll, getById, deleteById, create, update };
};

export default usePublicAPIClient;
