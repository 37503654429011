import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useSearchParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import TextInput from "../../../core/form-components/TextInput";
import useResetPassword from "./../../../core/hooks/useResetPassword";
import Button from "../../../core/form-components/Button";
import PageLoader from "../../../core/loader/pageloader";
import Alert from "../../../core/alert/Alert";

const schema = z.object({
  password: z
    .string({
      invalid_type_error: "Password is required.",
    })
    .nonempty("Username is required"),
});

const Resetpassword = () => {
  const [searchParam] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const resetPassword = useResetPassword();

  const token = searchParam.get("token");
  const userId = Number(searchParam.get("id"));

  const onSubmit = (data) => {
    const request = {
      token,
      userId,
      password: data.password,
    };
    resetPassword.mutate(request);
  };
  const route = all_routes;
  return (
    <>
      {resetPassword.isPending && <PageLoader />}
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper login-new">
            <div className="container">
              <div className="login-content user-login">
                <div className="login-logo">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" alt />
                  </Link>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="login-userset">
                    <div className="login-userheading">
                      <h3>Reset password?</h3>
                      <h4>
                        Enter your new password to reset password. Then you will
                        login with the new password.If you have any issue about
                        reset password contact us
                      </h4>
                      {resetPassword.error && (
                        <Alert
                          label="Failed to Reset Password. Please try again"
                          theme="danger"
                        />
                      )}
                      {resetPassword.isSuccess && (
                        <Alert
                          label="Password successfully reset. Please login"
                          theme="success"
                        />
                      )}
                    </div>
                    <div className="form-login">
                      <div className="form-addons">
                        <TextInput
                          label="Password"
                          register={register("password")}
                          errors={errors.password}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <div className="d-grid gap-2 mb-4">
                        <Button color="secondary" label="Reset Password" />
                      </div>
                    </div>
                    <div className="signinform text-center">
                      <h4>
                        Return to
                        <Link to={route.signin} className="hover-a">
                          {" "}
                          login
                        </Link>
                      </h4>
                    </div>
                  </div>
                </form>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © 2023 DreamsPOS. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resetpassword;
