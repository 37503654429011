import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetInvoiceById = (id) => {
  const { getById } = usePrivateAPIClient(baseUrl, "/api/v1/pos/invoice", true);
  return useQuery({
    queryKey: ["invoice", id],
    queryFn: () => getById(id),
    enabled: !!id,
  });
};

export default useGetInvoiceById;
