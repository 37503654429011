import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";
import { MEAL_CATEGORY_STATUSES } from "../queries/queryKeys";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetMealCategoryStatuses = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/meal-category-statuses",
    true
  );
  return useQuery({
    queryKey: [MEAL_CATEGORY_STATUSES],
    queryFn: () => getAll(),
  });
};

export default useGetMealCategoryStatuses;
