import React from "react";
import PropTypes from "prop-types";
import { createContext, useState } from "react";

const defaultAuth = {
  username: "",
  permissions: {},
  role: "user", // Default role if not specified
  accessToken: "",
  userId: 0,
};

const AuthContext = createContext({
  auth: defaultAuth,
  setAuth: () => {},
});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist")) || false
  );
  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthContext;
