import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";
import { PRODUCT_CATEGORY_STATUSES } from "../queries/queryKeys";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetProductCategoryStatuses = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/product-category-statuses",
    true
  );
  return useQuery({
    queryKey: [PRODUCT_CATEGORY_STATUSES],
    queryFn: () => getAll(),
  });
};

export default useGetProductCategoryStatuses;
