import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useAddMealCategory = () => {
  const { create } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/meal-categories",
    true
  );
  return useMutation({
    mutationFn: (data) =>
      create(data, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
  });
};

export default useAddMealCategory;
