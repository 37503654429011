import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Check } from "react-feather";
import useGetOrders from "../../hooks/useGetOrders";
import useGetProducts from "../../hooks/useGetProducts";
import useGetMeals from "../../hooks/useGetMeals";
import Button from "../../form-components/Button";
import PrintInvoice from "./printinvoice";
import Modal from "../Modal";
import PrintReceipt from "./printreceipt";
import SelectPaymentMethod from "./selectpaymentmethod";

export default function RecentOrders() {
  const { data: orderData, isSuccess } = useGetOrders();
  const { data: productData } = useGetProducts();
  const { data: mealData } = useGetMeals();

  const [isPrintInvoiceModalOpen, setIsPrintInvoiceModalOpen] = useState(false);
  const [isPrintReceiptModalOpen, setIsPrintReceiptModalOpen] = useState(false);
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState(false);
  const [invoiceId, setInvoiceId] = useState();

  const combinedItems = [
    ...(productData?.data?.products || []).map((product) => ({
      ...product,
      type: "product",
    })),
    ...(mealData?.data?.meals || []).map((meal) => ({ ...meal, type: "meal" })),
  ];

  const unpaidOrders = isSuccess
    ? orderData.data.orders.filter((order) => order.status === "PENDING")
    : [];
  const paidOrders = isSuccess
    ? orderData.data.orders.filter((order) => order.status === "PAID")
    : [];
  const unsettledOrders = isSuccess
    ? orderData.data.orders.filter((order) => order.status === "UNSETTLED")
    : [];

  useEffect(() => {}, [orderData, productData, mealData]);

  const renderOrders = (orders, tab) =>
    orders.map((order, key) => (
      <div key={key} className="default-cover p-4 mb-4">
        <span className="badge bg-warning d-inline-block mb-4">
          Order ID : {order.invoiceId}
        </span>
        <div className="row">
          <div className="col-sm-12 col-md-6 record mb-3">
            <table>
              <tbody>
                <tr className="mb-3">
                  <td>Customer Name</td>
                  <td className="colon">:</td>
                  <td className="text">
                    {order.name ? order.name : "Walk-In"}
                  </td>
                </tr>
                <tr>
                  <td>Transaction Id</td>
                  <td className="colon">:</td>
                  <td className="text">{order.transactionId}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td className="colon">:</td>
                  <td className="text">{order.status}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-12 col-md-6 record mb-3">
            <table>
              <tbody>
                <tr>
                  <td>Total</td>
                  <td className="colon">:</td>
                  <td className="text">{`GNF ${order.totalAmount}`}</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td className="colon">:</td>
                  <td className="text">10-09-2023 17:15:11</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="pos-products">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-3">Items</h5>
          </div>
          <div className="tabs_container">
            <div className="tab_content active" data-tab="all">
              <div className="row">
                {order.orderItems.map((item, key) => {
                  const filteredProduct = combinedItems.find(
                    (citem) => citem.name === item.name
                  );
                  return (
                    filteredProduct && (
                      <div
                        key={key}
                        className="col-sm-6 col-md-6 col-lg-6 col-xl-6"
                      >
                        <div className="product-info default-cover card">
                          <Link to="#">
                            <ImageWithBasePath
                              src={filteredProduct.imageUrl}
                              alt="items"
                              type="R"
                            />
                            <span>
                              <Check className="feather-16" />
                            </span>
                          </Link>
                          <h6 className="cat-name">
                            <Link to="#">{filteredProduct.category}</Link>
                          </h6>
                          <h6 className="product-name">
                            <Link to="#">{filteredProduct.name}</Link>
                          </h6>
                          <div className="d-flex align-items-center justify-content-between price">
                            <span>{`${item.quantitySold} Pcs`}</span>
                            <p>{`GNF ${item.unitPrice}`}</p>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="btn-row d-flex align-items-center justify-content-between">
          <Button
            color="info"
            label="Print Invoice"
            onClick={() => {
              setInvoiceId(order.invoiceId);
              setIsPrintInvoiceModalOpen(true);
            }}
          />
          {tab === "unpaid" && (
            <Button
              color="info"
              label="Select Payment Method"
              onClick={() => {
                setInvoiceId(order.invoiceId);
                setIsPaymentMethodModalOpen(true);
              }}
            />
          )}
          {tab === "paid" && (
            <>
              <Button
                color="info"
                label="Print Receipt"
                onClick={() => {
                  setInvoiceId(order.invoiceId);
                  setIsPrintReceiptModalOpen(true);
                }}
              />
            </>
          )}
        </div>
      </div>
    ));

  return (
    <>
      {/* Recent Transactions */}

      <div className="modal-header p-4">
        <h5 className="modal-title">Orders</h5>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body p-4">
        <div className="tabs-sets">
          <ul className="nav nav-tabs" id="myTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="unpaid-tab"
                data-bs-toggle="tab"
                data-bs-target="#unpaid"
                type="button"
                aria-controls="unpaid"
                aria-selected="true"
                role="tab"
              >
                Unpaid
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="paid-tab"
                data-bs-toggle="tab"
                data-bs-target="#paid"
                type="button"
                aria-controls="paid"
                aria-selected="false"
                role="tab"
              >
                Paid
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="unsettled-tab"
                data-bs-toggle="tab"
                data-bs-target="#unsettled"
                type="button"
                aria-controls="unsettled"
                aria-selected="false"
                role="tab"
              >
                Unsettled
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="unpaid"
              role="tabpanel"
            >
              <div className="table-top">
                <div className="search-set w-100 search-order">
                  <div className="search-input w-100">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch w-100"
                    />
                    <Link to="#" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="order-body">
                {renderOrders(unpaidOrders, "unpaid")}
              </div>
            </div>
            <div className="tab-pane fade" id="paid" role="tabpanel">
              <div className="table-top">
                <div className="search-set w-100 search-order">
                  <div className="search-input w-100">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch w-100"
                    />
                    <Link to="#" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="order-body">
                {renderOrders(paidOrders, "paid")}
              </div>
            </div>
            <div className="tab-pane fade" id="unsettled" role="tabpanel">
              <div className="table-top">
                <div className="search-set w-100 search-order">
                  <div className="search-input w-100">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch w-100"
                    />
                    <Link to="#" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="order-body">{renderOrders(unsettledOrders)}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal modalId="print-invoice" show={isPrintInvoiceModalOpen}>
        <PrintInvoice
          invoiceId={invoiceId}
          hide={() => setIsPrintInvoiceModalOpen(false)}
        />
      </Modal>
      <Modal modalId="print-receipt" show={isPrintReceiptModalOpen}>
        <PrintReceipt
          invoiceId={invoiceId}
          hide={() => setIsPrintReceiptModalOpen(false)}
        />
      </Modal>
      <Modal modalId="payment-method" show={isPaymentMethodModalOpen}>
        <SelectPaymentMethod
          invoiceId={invoiceId}
          hide={() => setIsPaymentMethodModalOpen(false)}
        />
      </Modal>
    </>
  );
}
