export const lowstockdata = [
  {
    id: 1,
    img: "assets/img/products/stock-img-01.png",
    warehouse: "Lavish Warehouse",
    store: "Crinol",
    product: 'Lenovo 3rd Generation{" "}',
    category: "Laptop",
    sku: "PT001",
    qty: "15",
    qtyalert: "10",
  },
  {
    id: 2,
    img: "assets/img/products/stock-img-02.png",
    warehouse: "Lobar Handy",
    store: "Selosy",
    product: "Nike Jordan",
    category: "Shoe",
    sku: "PT002",
    qty: "17",
    qtyalert: "08",
  },
  {
    id: 3,
    img: "assets/img/products/stock-img-03.png",
    warehouse: "Quaint Warehouse",
    store: "Logerro",
    product: 'Apple Series 5 Watch{" "}',
    category: "Electronics",
    sku: "PT003",
    qty: "14",
    qtyalert: "12",
  },
  {
    id: 4,
    img: "assets/img/products/stock-img-04.png",
    warehouse: "Traditional Warehouse",
    store: "Vesloo",
    product: "Amazon Echo Dot",
    category: "Speaker",
    sku: "PT004",
    qty: "20",
    qtyalert: "15",
  },
  {
    id: 5,
    img: "assets/img/products/stock-img-05.png",
    warehouse: "Cool Warehouse",
    store: "Crompy",
    product: "Lobar Handy",
    category: "Furnitures",
    sku: "PT005",
    qty: "18",
    qtyalert: "13",
  },
];
