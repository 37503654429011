export function arePermissionsAllowed(allowedPermissions, userPermissions) {
  const isAllowed = Object.entries(allowedPermissions).some(
    ([entity, actions]) => {
      return actions.every((action) => {
        return userPermissions?.[entity]?.includes(action);
      });
    }
  );
  return isAllowed;
}
