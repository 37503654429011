import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";
import { EXPENSE_CATEGORIES_QUERY_KEY } from "../queries/queryKeys";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetExpenseCategories = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/expense-categories",
    true
  );
  return useQuery({
    queryKey: [EXPENSE_CATEGORIES_QUERY_KEY],
    queryFn: () => getAll(),
  });
};

export default useGetExpenseCategories;
