import React from "react";
import { image_base_url } from "../../environment";
import { image_path } from "../../environment";
interface Image {
  className?: string;
  src: string;
  alt?: string;
  height?: number;
  width?: number;
  id?: string;
  type?: string; //R=remote, L=local
}

const ImageWithBasePath = ({
  alt,
  src,
  className,
  height,
  width,
  id,
  type = "L",
}: Image) => {
  // Combine the base path and the provided src to create the full image source URL
  const altText = String(alt);
  const fullSrc = `${type == "R" ? image_base_url : image_path}${src}`;
  return (
    <img
      className={className}
      src={fullSrc}
      height={height}
      alt={altText}
      width={width}
      id={id}
    />
  );
};

export default ImageWithBasePath;
