import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";
import getEnvVariable from "../../getEnvVariable";
import { PRODUCT_UNIT_TYPES_QUERY_KEY } from "../queries/queryKeys";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const usetGetProductUnitTypes = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/product-unit-types",
    true
  );
  return useQuery({
    queryKey: [PRODUCT_UNIT_TYPES_QUERY_KEY],
    queryFn: () => getAll(),
  });
};

export default usetGetProductUnitTypes;
