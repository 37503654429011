import axios from "axios";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";

const useAxiosPrivate = (baseURL, isWithCredentials) => {
  const { mutateAsync: refreshToken } = useRefreshToken();
  const { auth } = useAuth();

  const axiosPrivate = axios.create({
    baseURL,
    withCredentials: isWithCredentials,
  });

  axiosPrivate.interceptors.request.use(
    (config) => {
      const token = auth?.accessToken;
      if (token && !config.headers["Authorization"]) {
        console.log("Attaching token:", token); // Debug log
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 403 && !prevRequest?.sent) {
        prevRequest.sent = true;
        try {
          const newTokenResponse = await refreshToken({});
          console.log("New token:", newTokenResponse.accessToken); // Debug log
          prevRequest.headers[
            "Authorization"
          ] = `Bearer ${newTokenResponse.accessToken}`;
          return axiosPrivate(prevRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosPrivate;
};

export default useAxiosPrivate;
