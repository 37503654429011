import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetActiveBookings = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/pos/active-bookings",
    true
  );
  return useQuery({
    queryKey: ["active-bookings"],
    queryFn: () => getAll(),
  });
};

export default useGetActiveBookings;
