import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Breadcrumbs from "../../core/breadcrumbs";
import useDeleteExpenseCategory from "../../core/hooks/useDeleteExpenseCategory";
import useGetExpenseCategories from "../../core/hooks/useGetExpenseCategories";
import AddExpenseCategory from "../../core/modals/inventory/addexpensecategory";
import EditExpenseCategory from "../../core/modals/inventory/editexpensecategory";

import useAuth from "../../core/hooks/useAuth";
import { arePermissionsAllowed } from "../../Router/permissionChecker";

const ExpenseCategory = () => {
  const [currentRecord, setCurrentRecord] = useState();
  const deleteExpenseCategory = useDeleteExpenseCategory();
  const { data: expenseCategoriesData, isSuccess } = useGetExpenseCategories();

  const MySwal = withReactContent(Swal);

  const { auth } = useAuth();

  const userPermissions = auth.permissions || {};

  const isUpdateAllowed = arePermissionsAllowed(
    { HOME: ["READ"] },
    userPermissions
  );

  const isDeleteAllowed = arePermissionsAllowed(
    { HOME: ["READ"] },
    userPermissions
  );

  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deleteExpenseCategory.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The meal category has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        onError: () => {
          MySwal.fire({
            title: "Not Deleted!",
            text: "Failed to delete meal category.",
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const actionColumn =
    isUpdateAllowed || isDeleteAllowed
      ? {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          render: (text, record) => (
            <div className="action-table-data">
              <div className="edit-delete-action">
                {isUpdateAllowed && record.status != "Approved" && (
                  <Link
                    className="me-2 p-2 mb-0"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                    onClick={() => setCurrentRecord(record)}
                  >
                    <i data-feather="edit" className="feather-edit"></i>
                  </Link>
                )}
                {isDeleteAllowed && record.status != "Approved" && (
                  <Link
                    className="me-3 confirm-text p-2 mb-0"
                    to="#"
                    onClick={() =>
                      showDeleteConfirmationAlert(() =>
                        handleDeleteConfirm(record.id)
                      )
                    }
                  >
                    <i data-feather="trash-2" className="feather-trash-2"></i>
                  </Link>
                )}
              </div>
            </div>
          ),
        }
      : {};

  const baseColumns = [
    {
      title: "Category Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
  ];
  const columns = [...baseColumns, actionColumn];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const [searchText, setSearchText] = useState("");

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="content">
            <Breadcrumbs
              maintitle="Expense Category List "
              subtitle="Manage Your Expense Category"
              addButton="Add New Category"
            />
            {/* /product list */}
            <div className="card table-list-card">
              <div className="card-body">
                <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        aria-controls="DataTables_Table_0"
                        value={searchText}
                        onChange={handleSearch}
                      />
                      <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  {isSuccess && (
                    <Table
                      columns={columns}
                      dataSource={expenseCategoriesData.data.expenses}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
        {/* Add Expense Category*/}
        <AddExpenseCategory />
        {/* /Add Expense Category*/}

        {/* Edit Expense Category*/}
        <EditExpenseCategory currentRecord={currentRecord} />
        {/* /Edit Expense */}
      </div>
    </>
  );
};

export default ExpenseCategory;
