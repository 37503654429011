export const productlistdata = [
  {
    id: 1,
    product: "Lenovo 3rd Generation",
    description: "Lenovo 3rd Generation",
    productImage: "assets/img/products/stock-img-01.png",
    sku: "PT001",
    category: "Laptop",
    brand: "Lenovo",
    price: "$12500.00",
    unit: "Pc",
    qty: "100",
    createdby: "Arroon",
    img: "assets/img/users/user-30.jpg",
  },
];
