import React from "react";
import PropTypes from "prop-types";
const TextInput = ({ register, label, errors }) => {
  return (
    <>
      <div className="input-blocks add-product">
        <label>{label}</label>
        <input {...register} type="text" className="form-control" />
      </div>
      {errors && <p className="text-danger">{errors.message}</p>}
    </>
  );
};

TextInput.propTypes = {
  register: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.string,
};

export default TextInput;
