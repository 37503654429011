import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useAddPosOrder = () => {
  const { create } = usePrivateAPIClient(baseUrl, "/api/v1/pos/orders", true);
  return useMutation({
    mutationFn: (data) => create(data),
  });
};

export default useAddPosOrder;
