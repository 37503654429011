import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetPosTransactionNo = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/pos/transaction-number",
    true
  );
  return useQuery({
    queryKey: ["transaction-number"],
    queryFn: () => getAll(),
  });
};

export default useGetPosTransactionNo;
