import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
export default function OrderConfirmation(onOrderConfirmed) {
  MySwal.fire({
    title: "Are you sure you want to place an order?",
    text: "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonColor: "#00ff00",
    confirmButtonText: "Yes, order it!",
    cancelButtonColor: "#ff0000",
    cancelButtonText: "Cancel",
  }).then((result) => {
    if (result.isConfirmed) {
      onOrderConfirmed();
    } else {
      MySwal.close();
    }
  });
}
