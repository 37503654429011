import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../Router/all_routes";
import Addunits from "../../core/modals/inventory/addunits";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  LifeBuoy,
} from "feather-icons-react/build/IconComponents";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TextInput from "../../core/form-components/TextInput";
import TextAreaInput from "../../core/form-components/TextAreaInput";
import SelectInput from "../../core/form-components/SelectInput";
import { useForm } from "react-hook-form";
import useAddProduct from "./../../core/hooks/useAddProduct";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import FileUploadInput from "../../core/form-components/FileUploadInput";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Button from "../../core/form-components/Button";
import useAuth from "../../core/hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { PRODUCTS_QUERY_KEY } from "../../core/queries/queryKeys";
import useGetProductCategories from "../../core/hooks/useGetProductCategories";
import useGetProducts from "./../../core/hooks/useGetProducts";
import useGetSuppliers from "./../../core/hooks/useGetSuppliers";
import useGetProductUnitTypes from "./../../core/hooks/useGetProductUnitTypes";
const schema = z
  .object({
    name: z.string().min(1, { message: "Name cannot be empty" }),
    description: z.string().min(1, { message: "Description cannot be empty" }),
    categoryId: z.number().min(1, { message: "Category cannot be empty" }),
    costPrice: z.number().min(1, { message: "Cost Price cannot be empty" }),
    unitPrice: z.number().min(1, { message: "Price cannot be empty" }),
    supplierId: z.number().min(1, { message: "Supplier cannot be empty" }),
    quantityOnHand: z.number().min(1, { message: "Quantity cannot be empty" }),
    reorderLevel: z
      .number()
      .min(1, { message: "Reorder level cannot be empty" }),
    isPortion: z.boolean(),
    bulkProductId: z.number().optional(),
    portionPerBulk: z.number().optional(),
    unitTypeId: z.number().min(1, { message: "Unit Type cannot be empty" }),
    images: z.any(),
  })
  .refine(
    (data) => {
      if (data.isPortion) {
        return (
          data.bulkProductId !== undefined && data.portionPerBulk !== undefined
        );
      }
      return true;
    },
    {
      message:
        "Bulk Product and Portion Per Bulk are required when it is portion",
      path: ["bulkProductId", "portionPerBulk"],
    }
  );

const AddProduct = () => {
  const queryClient = useQueryClient();
  const { auth } = useAuth();

  const route = all_routes;

  const MySwal = withReactContent(Swal);

  const [previewURLs, setPreviewURLs] = useState([]);
  const [isPortion, setIsPortion] = useState(false);

  const addProduct = useAddProduct();

  const { data: productCategories } = useGetProductCategories();

  const categories =
    productCategories?.data?.productCategories?.map((status) => ({
      value: status.id,
      label: status.name,
    })) || [];

  const { data: supplierData } = useGetSuppliers();

  const suppliers =
    supplierData?.data?.suppliers?.map((supplier) => ({
      value: supplier.id,
      label: supplier.name,
    })) || [];

  const { data: productsData } = useGetProducts();

  const products =
    productsData?.data?.products?.map((status) => ({
      value: status.id,
      label: status.name,
    })) || [];

  const { data: productUnitTypesData } = useGetProductUnitTypes();

  const unitTypes =
    productUnitTypesData?.data?.unitTypes?.map((unitType) => ({
      value: unitType.id,
      label: unitType.name,
    })) || [];

  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this product?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });
    formData.append("userId", auth.userId);
    addProduct.mutate(formData, {
      onSuccess: () => {
        MySwal.fire({
          title: "Created!",
          text: "The product has been created.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          queryClient.invalidateQueries([PRODUCTS_QUERY_KEY]);
        });
      },
      onError: () => {
        MySwal.fire({
          title: "Not Created!",
          text: "Failed to create product.",
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      isPortion: false, // Ensure default value is set
    },
  });

  const watchIsPortion = watch("isPortion");

  useEffect(() => {
    if (watchIsPortion !== undefined) {
      setIsPortion(watchIsPortion === true);
    }
  }, [watchIsPortion]);

  const onSubmit = (data) => {
    try {
      showCreateConfirmation(() => handleCreateConfirm(data));
    } catch (e) {
      console.log(e);
    }
  };

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>New Product</h4>
                <h6>Create new Product</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <div className="page-btn">
                  <Link to={route.productlist} className="btn btn-secondary">
                    <ArrowLeft className="me-2" />
                    Back to Product
                  </Link>
                </div>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Collapse"
                    id="collapse-header"
                  >
                    <ChevronUp className="feather-chevron-up" />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /add */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body add-product pb-0">
                <div
                  className="accordion-card-one accordion"
                  id="accordionExample2"
                >
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <div
                        className="accordion-button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-controls="collapseTwo"
                      >
                        <div className="text-editor add-list">
                          <div className="addproduct-icon list icon">
                            <h5>
                              <LifeBuoy className="add-info" />
                              <span>Details of Product</span>
                            </h5>
                            <Link to="#">
                              <ChevronDown className="chevron-down-add" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div className="accordion-body">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <div className="input-blocks add-product">
                                  <SelectInput
                                    label="Is Portion"
                                    register={register("isPortion", {
                                      setValueAs: (v) => v === "true",
                                    })}
                                    options={[
                                      { value: "true", label: "Yes" },
                                      { value: "false", label: "No" },
                                    ]}
                                    errors={errors.isPortion}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextInput
                                  label="Name"
                                  register={register("name")}
                                  errors={errors.name}
                                />
                              </div>
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextAreaInput
                                  label="Description"
                                  register={register("description")}
                                  errors={errors.description}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <div className="input-blocks add-product">
                                  <SelectInput
                                    label="Category"
                                    options={categories}
                                    register={register("categoryId", {
                                      setValueAs: (v) => parseInt(v, 10),
                                    })}
                                    errors={errors.categoryId}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextInput
                                  label="Cost Price"
                                  register={register("costPrice", {
                                    setValueAs: (v) => parseInt(v, 10),
                                  })}
                                  errors={errors.costPrice}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <div className="input-blocks add-product">
                                  <SelectInput
                                    label="Supplier"
                                    options={suppliers}
                                    register={register("supplierId", {
                                      setValueAs: (v) => parseInt(v, 10),
                                    })}
                                    errors={errors.supplierId}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextInput
                                  label="Quantity"
                                  register={register("quantityOnHand", {
                                    setValueAs: (v) => parseInt(v, 10),
                                  })}
                                  errors={errors.quantityOnHand}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextInput
                                  label="Reorder Level"
                                  register={register("reorderLevel", {
                                    setValueAs: (v) => parseInt(v, 10),
                                  })}
                                  errors={errors.reorderLevel}
                                />
                              </div>

                              <div className="col-lg-6 col-sm-12 col-6">
                                <div className="input-blocks add-product">
                                  <SelectInput
                                    label="Unit Type"
                                    options={unitTypes}
                                    register={register("unitTypeId", {
                                      setValueAs: (v) => parseInt(v, 10),
                                    })}
                                    errors={errors.unitTypeId}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              {isPortion && (
                                <>
                                  <div className="col-lg-6 col-sm-12 col-6">
                                    <div className="input-blocks add-product">
                                      <SelectInput
                                        label="Bulk Product ID"
                                        options={products}
                                        register={register("bulkProductId", {
                                          setValueAs: (v) => parseInt(v, 10),
                                        })}
                                        errors={errors.bulkProductId}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-6">
                                    <TextInput
                                      label="Portion Per Bulk"
                                      register={register("portionPerBulk", {
                                        setValueAs: (v) => parseInt(v, 10),
                                      })}
                                      errors={errors.portionPerBulk}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextInput
                                  label="Selling Price"
                                  register={register("unitPrice", {
                                    setValueAs: (v) => parseInt(v, 10),
                                  })}
                                  errors={errors.unitPrice}
                                />
                              </div>
                            </div>
                            <div
                              className="accordion-card-one accordion"
                              id="accordionExample3"
                            >
                              <div className="accordion-item">
                                <div
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <div
                                    className="accordion-button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-controls="collapseThree"
                                  >
                                    <div className="addproduct-icon list">
                                      <h5>
                                        <i
                                          data-feather="image"
                                          className="add-info"
                                        />
                                        <span>Images</span>
                                      </h5>
                                      <Link to="#">
                                        <ChevronDown className="chevron-down-add" />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample3"
                                >
                                  <div className="accordion-body">
                                    <div className="text-editor add-list add">
                                      <div className="col-lg-12">
                                        <FileUploadInput
                                          register={register}
                                          previewURLs={previewURLs}
                                          setPreviewURLs={setPreviewURLs}
                                          errors={errors.images}
                                          maxFiles={1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="btn-addproduct mb-4">
                <Button
                  color="cancel"
                  label=" Cancel"
                  margin="me-2"
                  onClick={() => reset()}
                />
                <Button color="success" label="Place Order" />
              </div>
            </div>
          </form>
        </div>
        <Addunits />
        <AddCategory />
        <AddBrand />
      </div>
    </>
  );
};

export default AddProduct;
