export const EXPENSES_QUERY_KEY = "expenses";
export const EXPENSE_CATEGORIES_QUERY_KEY = "expense-categories";
export const PURCHASE_ORDER_QUERY_KEY = "purchasing-orders";
export const SUPPLIERS_QUERY_KEY = "suppliers";
export const PRODUCTS_QUERY_KEY = "products";
export const MEALS_QUERY_KEY = "meals";
export const MEAL_CATEGORY_STATUSES = "meal-category-statuses";
export const PRODUCT_CATEGORY_STATUSES = "product-category-statuses";
export const PRODUCT_UNIT_TYPES_QUERY_KEY = "product-unit-types";
export const PRODUCT_CATEGORIES_QUERY_KEY = "product-categories";
export const MEAL_CATEGORIES_QUERY_KEY = "meal-categories";
