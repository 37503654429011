import React from "react";
import { Link } from "react-router-dom";
import { CheckCircle } from "react-feather";
import Button from "../../form-components/Button";
import { useModal } from "../../context/ModalContext";
const AddedToInvoice = () => {
  const { hideModal } = useModal();
  return (
    <div className="modal-body text-center">
      <div className="icon-head">
        <Link to="#">
          <CheckCircle className="feather-40" />
        </Link>
      </div>
      <h4>Added to Invoice Completed</h4>
      <div className="d-grid gap-2 mb-4">
        <Button
          color="success"
          label="Close"
          onClick={() => {
            hideModal("added-to-invoice");
          }}
        />
      </div>
    </div>
  );
};
export default AddedToInvoice;
