import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import Button from "../../form-components/Button";
const PrintPurchaseOrder = ({ purchaseOrderDetails, isApproved, hide }) => {
  const navigate = useNavigate();
  const {
    poId: transactionId,
    supplierName: name,
    totalAmount: totalAmount,
    productList: orderItems,
    date,
  } = purchaseOrderDetails;

  const poDetails = {
    transactionId,
    name,
    totalAmount,
    orderItems,
    date,
  };

  const handlePrint = () => {
    navigate(all_routes.salesvoucher, {
      state: { transactionInfo: poDetails, documentType: "purchase-order" },
    });
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="close p-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={hide}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="icon-head text-center">
          <Link to="#">
            <ImageWithBasePath
              src="assets/img/logo.png"
              width={100}
              height={30}
              alt="Receipt Logo"
            />
          </Link>
        </div>
        <div className="text-center info text-center">
          <h6>Diamond Hotel Kipe</h6>
          <p className="mb-0">Phone Number: +224 629696944 / 620354668</p>
          <p className="mb-0">
            Email:
            <Link to="mailto:example@gmail.com">info@diamondhotelkipe.com</Link>
          </p>
        </div>
        <div className="tax-invoice">
          <h6 className="text-center">Purchasing Order (For Supplier Use)</h6>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="invoice-user-name">
                <span>Supplier: </span>
                <span>{`${name}`}</span>
              </div>
              <div className="invoice-user-name">
                <span>Po Id: </span>
                <span>{`${transactionId}`}</span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="invoice-user-name">
                <span>Date: </span>
                <span>{`${date}`}</span>
              </div>
            </div>
          </div>
        </div>
        <table className="table-borderless w-100 table-fit">
          <thead>
            <tr>
              <th># Item</th>
              <th>Price (GNF)</th>
              <th>Qty</th>
              <th className="text-end">Total</th>
            </tr>
          </thead>
          <tbody>
            {orderItems &&
              orderItems.map((item, key) => (
                <tr key={key}>
                  <td>{item.name}</td>
                  <td>{`${item.unitPrice}`}</td>
                  <td>{item.quantitySold}</td>
                  <td className="text-end">{item.lineTotalAmount}</td>
                </tr>
              ))}
            <tr>
              <td colSpan={4}>
                <table className="table-borderless w-100 table-fit">
                  <tbody>
                    <tr>
                      <td>Total :</td>
                      <td className="text-end">{`GNF ${totalAmount}`}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        {isApproved && (
          <div className="text-center invoice-bar">
            <p>
              Thank you for your prompt service. We look forward to continuing
              our partnership
            </p>
            <Button
              color="info"
              label="Print Purchase Order"
              onClick={handlePrint}
            />
          </div>
        )}
      </div>
    </>
  );
};
PrintPurchaseOrder.propTypes = {
  purchaseOrderDetails: PropTypes.object,
  isApproved: PropTypes.any,
  hide: PropTypes.any,
};

export default PrintPurchaseOrder;
