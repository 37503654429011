import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useUpdateProductCategory from "../../hooks/useUpdateProductCategory";
import TextInput from "../../form-components/TextInput";
import SelectInput from "../../form-components/SelectInput";
import FileUploadInput from "../../form-components/FileUploadInput";
import Button from "../../form-components/Button";
import useAuth from "../../hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { PRODUCT_CATEGORIES_QUERY_KEY } from "../../queries/queryKeys";

const schema = z.object({
  name: z.string().min(1, { message: "Name cannot be empty" }),
  slug: z.string().min(1, { message: "Slug cannot be empty" }),
  statusId: z.any(),
  images: z.any(),
});

const EditCategoryList = ({ currentRecord: productCategory }) => {
  const [previewURLs, setPreviewURLs] = useState([]);
  const updateProductCategory = useUpdateProductCategory();
  const { auth } = useAuth();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (productCategory) {
      reset({
        name: productCategory.name,
        slug: productCategory.slug,
        statusId: 1,
      });
    }
  }, [productCategory, reset]);

  const MySwal = withReactContent(Swal);

  const showUpdateConfirmation = (handleUpdateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to edit this product category?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, update it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleUpdateConfirm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });
    formData.append("userId", auth.userId);
    updateProductCategory.mutate(
      { id: productCategory.id, formData },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Updated!",
            text: "The product has been updated.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([PRODUCT_CATEGORIES_QUERY_KEY]);
          });
        },
        onError: () => {
          MySwal.fire({
            title: "Not Updated!",
            text: "Failed to update product.",
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const onSubmit = async (data) => {
    try {
      showUpdateConfirmation(() => handleUpdateConfirm(data));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {/* Edit Category */}
      <div className="modal fade" id="edit-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Name"
                        register={register("name")}
                        errors={errors.name}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Slug"
                        register={register("slug")}
                        errors={errors.slug}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        label="Status"
                        options={[{ value: 1, label: "Active" }]}
                        register={register("statusId", {
                          setValueAs: (v) => parseInt(v, 10),
                        })}
                        errors={errors.categoryId}
                      />
                    </div>
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Image</span>
                        <FileUploadInput
                          register={register}
                          previewURLs={previewURLs}
                          setPreviewURLs={setPreviewURLs}
                          errors={errors.images}
                          maxFiles={1}
                        />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="success" label="Save Changes" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Category */}
    </div>
  );
};

EditCategoryList.propTypes = {
  currentRecord: PropTypes.any.isRequired,
};

export default EditCategoryList;
