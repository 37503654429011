import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { z } from "zod";
import useForgotPassword from "../../../core/hooks/useForgotPassword";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import TextInput from "../../../core/form-components/TextInput";
import Button from "../../../core/form-components/Button";
import PageLoader from "../../../core/loader/pageloader";
import Alert from "../../../core/alert/Alert";

const schema = z.object({
  username: z
    .string({
      invalid_type_error: "Username is required.",
    })
    .nonempty("Username is required"),
});

const Forgotpassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const forgotPassword = useForgotPassword();

  const onSubmit = (data) => {
    const request = {
      username: data.username,
      platformId: 2,
    };
    forgotPassword.mutate(request);
  };
  const route = all_routes;
  return (
    <>
      {forgotPassword.isPending && <PageLoader />}
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper login-new">
            <div className="container">
              <div className="login-content user-login">
                <div className="login-logo">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" alt />
                  </Link>
                </div>
                <form action="signin-3" onSubmit={handleSubmit(onSubmit)}>
                  <div className="login-userset">
                    <div className="login-userheading">
                      <h3>Forgot password?</h3>
                      <h4>
                        If you forgot your password, well, then we’ll email you
                        instructions to reset your password.
                      </h4>
                      {forgotPassword.error && (
                        <Alert
                          label="Failed to Reset Password. Please try again"
                          theme="danger"
                        />
                      )}
                      {forgotPassword.isSuccess && (
                        <Alert
                          label="Password reset link sent successfully"
                          theme="success"
                        />
                      )}
                    </div>
                    <div className="form-login">
                      <div className="form-addons">
                        <TextInput
                          label="Username"
                          register={register("username")}
                          errors={errors.username}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <div className="d-grid gap-2 mb-4">
                        <Button color="secondary" label="Send Reset Link" />
                      </div>
                    </div>
                    <div className="signinform text-center">
                      <h4>
                        Return to
                        <Link to={route.signin} className="hover-a">
                          {" "}
                          login
                        </Link>
                      </h4>
                    </div>
                  </div>
                </form>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © 2023 DreamsPOS. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
