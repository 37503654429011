import React from "react";
import PropTypes from "prop-types";
const TextAreaInput = ({ label, register, errors }) => {
  return (
    <>
      <div className="col-lg-12">
        <div className="input-blocks summer-description-box transfer mb-3">
          <label>{label}</label>
          <textarea
            {...register}
            className="form-control h-100"
            rows={5}
            defaultValue={""}
          />
          <p className="mt-1">Maximum 60 Characters</p>
        </div>
      </div>
      {errors && <p className="text-danger">{errors.message}</p>}
    </>
  );
};
TextAreaInput.propTypes = {
  register: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.string,
};
export default TextAreaInput;
