import React, { useEffect, useState } from "react";
import { User } from "feather-icons-react/build/IconComponents";
import useGetDashboardMetrics from "../../core/hooks/useGetDashboardMetrics";

const Dashboard = () => {
  const [dashboardMetrics, setDashboardMetrics] = useState();
  const { data: dashboardMetricsData } = useGetDashboardMetrics();

  useEffect(() => {
    if (dashboardMetricsData) {
      const metrics = Object.entries(dashboardMetricsData.data).map(
        ([key, value], index) => ({
          label: key,
          value: value,
          theme: index === 0 ? "" : `das${index}`,
        })
      );
      setDashboardMetrics(metrics);
    }
  }, [dashboardMetricsData]);
  return (
    <div>
      {dashboardMetrics && (
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              {dashboardMetrics.map((metric, index) => (
                <div className="col-xl-3 col-sm-6 col-12 d-flex" key={index}>
                  <div className={`dash-count ${metric.theme}`}>
                    <div className="dash-counts">
                      <h4>{metric.value}</h4>
                      <h5>{metric.label}</h5>
                    </div>
                    <div className="dash-imgs">
                      <User />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Button trigger modal */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
