export const brandlistdata = [
  {
    id: 1,
    brand: "Lenevo",
    logo: "assets/img/brand/brand-icon-01.png",
    createdon: "25 May 2023",
    status: "Active",
  },
  {
    id: 2,
    brand: "Boat",
    logo: "assets/img/brand/brand-icon-02.png",
    createdon: "24 Jun 2023",
    status: "Active",
  },
  {
    id: 3,
    brand: "Nike",
    logo: "assets/img/brand/brand-icon-03.png",
    createdon: "23 Jul 2023",
    status: "Active",
  },
  {
    id: 4,
    brand: "Apple",
    logo: "assets/img/brand/brand-icon-04.png",
    createdon: "22 Aug 2023",
    status: "Active",
  },
  {
    id: 5,
    brand: "Amazon",
    logo: "assets/img/brand/brand-icon-05.png",
    createdon: "21 Sep 2023",
    status: "Active",
  },
  {
    id: 6,
    brand: "Woodmart",
    logo: "assets/img/brand/brand-icon-06.png",
    createdon: "20 Sep 2023",
    status: "Active",
  },
  {
    id: 7,
    brand: "Versace",
    logo: "assets/img/brand/brand-icon-07.png",
    createdon: "20 Sep 2023",
    status: "Active",
  },
  {
    id: 8,
    brand: "Lava",
    logo: "assets/img/brand/brand-icon-08.png",
    createdon: "20 Sep 2023",
    status: "Active",
  },
  {
    id: 9,
    brand: "Bently",
    logo: "assets/img/brand/brand-icon-09.png",
    createdon: "20 Sep 2023",
    status: "Active",
  },
  {
    id: 10,
    brand: "Nilkamal",
    logo: "assets/img/brand/brand-icon-10.png",
    createdon: "20 Sep 2023",
    status: "Active",
  },
];
