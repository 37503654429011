import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useDeleteMeal = () => {
  const { deleteById } = usePrivateAPIClient(baseUrl, "/api/v1/meals", true);
  return useMutation({
    mutationFn: ({ id, userId }) => deleteById(id, { params: { userId } }),
  });
};

export default useDeleteMeal;
