import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useResetPassword = () => {
  const { create } = new usePrivateAPIClient(
    baseUrl,
    "/api/v1/reset-password",
    true
  );
  return useMutation({
    mutationFn: (data) => create(data),
  });
};

export default useResetPassword;
