import React, { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import FileUploadInput from "../../form-components/FileUploadInput";
import TextInput from "../../form-components/TextInput";
import useAddSupplier from "../../hooks/useAddSupplier";
import Button from "../../form-components/Button";
import useAuth from "../../hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { SUPPLIERS_QUERY_KEY } from "./../../queries/queryKeys";

const schema = z.object({
  name: z.string().min(1, { message: "Name cannot be empty" }),
  contactPerson: z
    .string()
    .min(1, { message: "Contact Person cannot be empty" }),
  contactNumber: z
    .string()
    .min(1, { message: "Contact Number cannot be empty" }),
  email: z.string().min(1, { message: "Email cannot be empty" }),
  address: z.string().min(1, { message: "Address cannot be empty" }),
  city: z.string().min(1, { message: "City cannot be empty" }),
  country: z.string().min(1, { message: "Country cannot be empty" }),
  images: z.any(),
});

const SupplierModal = () => {
  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const [previewURLs, setPreviewURLs] = useState([]);

  const addSupplier = useAddSupplier();
  const MySwal = withReactContent(Swal);

  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this supplier?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });
    formData.append("userId", auth.userId);
    addSupplier.mutate(formData, {
      onSuccess: () => {
        MySwal.fire({
          title: "Created!",
          text: "The supplier has been created.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          queryClient.invalidateQueries(SUPPLIERS_QUERY_KEY);
        });
      },
      onError: () => {
        MySwal.fire({
          title: "Not Created!",
          text: "Failed to create supplier.",
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    try {
      showCreateConfirmation(() => handleCreateConfirm(data));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Supplier</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Name"
                        register={register("name")}
                        errors={errors.name}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Contact Person"
                        register={register("contactPerson")}
                        errors={errors.contactPerson}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Contact Number"
                        register={register("contactNumber")}
                        errors={errors.contactNumber}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Email"
                        register={register("email")}
                        errors={errors.email}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Address"
                        register={register("address")}
                        errors={errors.address}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="City"
                        register={register("city")}
                        errors={errors.city}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Country"
                        register={register("country")}
                        errors={errors.country}
                      />
                    </div>
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Image</span>
                        <FileUploadInput
                          register={register}
                          previewURLs={previewURLs}
                          setPreviewURLs={setPreviewURLs}
                          errors={errors.images}
                          maxFiles={1}
                        />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="success" label="Place Order" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default SupplierModal;
