import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetMealOrdersStatuses = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/pos/kitchen-order-statuses",
    true
  );
  return useQuery({
    queryKey: ["meal-statuses"],
    queryFn: () => getAll(),
  });
};

export default useGetMealOrdersStatuses;
