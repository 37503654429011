import React from "react";

import * as Icon from "react-feather";

export const SidebarData = [
  {
    label: "Main",
    showSubRoute: false,
    submenuHdr: "Dashboard",
    submenuItems: [
      {
        label: "Dashboard",
        link: "/admin-dashboard",
        icon: <Icon.Grid />,
        submenu: false,
        showSubRoute: false,
      },
    ],
  },
  {
    label: "Stock",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Stock",
    submenuItems: [
      {
        label: "Products",
        link: "/product-list",
        icon: <Icon.Box />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Create Product",
        link: "/add-product",
        icon: <Icon.PlusSquare />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Category",
        link: "/category-list",
        icon: <Icon.Codepen />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Meals",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Meals",
    submenuItems: [
      {
        label: "Meal",
        link: "/meal-list",
        icon: <Icon.Box />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Create Meal",
        link: "/add-meal",
        icon: <Icon.PlusSquare />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Category",
        link: "/meal-category-list",
        icon: <Icon.Codepen />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Sales",
    submenuOpen: true,
    submenuHdr: "Sales",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "POS",
        link: "/pos",
        icon: <Icon.HardDrive />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "KDS",
        link: "/kitchen-display",
        icon: <Icon.Monitor />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "MOD",
        link: "/meal-order-display",
        icon: <Icon.Eye />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Purchases",
    submenuOpen: true,
    submenuHdr: "Purchases",
    showSubRoute: false,
    submenuItems: [
      {
        label: "Purchases",
        link: "/purchase-list",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },

  {
    label: "Finance & Accounts",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Finance & Accounts",
    submenuItems: [
      {
        label: "Expenses",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.FileText />,
        submenuItems: [
          { label: "Expenses", link: "/expense-list", showSubRoute: false },
          {
            label: "Expense Category",
            link: "/expense-category",
            showSubRoute: false,
          },
        ],
      },
    ],
  },

  {
    label: "People",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "People",

    submenuItems: [
      {
        label: "Suppliers",
        link: "/suppliers",
        icon: <Icon.Users />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Settings",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Settings",
    submenuItems: [
      {
        label: "Change Password",
        link: "/change-password",
        icon: <Icon.Key />,
        showSubRoute: false,
      },
      {
        label: "Logout",
        link: "/logout",
        icon: <Icon.LogOut />,
        showSubRoute: false,
      },
    ],
  },
];
