import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetMealCategories = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/meal-categories",
    true
  );
  return useQuery({
    queryKey: ["meal-categories"],
    queryFn: () => getAll(),
  });
};

export default useGetMealCategories;
