import React, { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import FileUploadInput from "../../form-components/FileUploadInput";
import TextInput from "../../form-components/TextInput";
import SelectInput from "../../form-components/SelectInput";
import useAddMealCategory from "../../hooks/useAddMealCategory";
import Button from "../../form-components/Button";
import useAuth from "../../hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { MEAL_CATEGORIES_QUERY_KEY } from "./../../queries/queryKeys";
import useGetMealCategoryStatuses from "../../hooks/useGetMealCategoryStatuses";

const schema = z.object({
  name: z.string().min(1, { message: "Name cannot be empty" }),
  slug: z.string().min(1, { message: "Slug cannot be empty" }),
  statusId: z.any(),
  images: z.any(),
});

const AddMealCategoryList = () => {
  const [previewURLs, setPreviewURLs] = useState([]);

  const addMealCategory = useAddMealCategory();
  const MySwal = withReactContent(Swal);

  const queryClient = useQueryClient();

  const { data: mealCategoryStatusData } = useGetMealCategoryStatuses();

  const categoryStatuses =
    mealCategoryStatusData?.data?.statuses?.map((status) => ({
      value: status.id,
      label: status.name,
    })) || [];

  const { auth } = useAuth();
  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this meal category?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });
    formData.append("userId", auth.userId);
    addMealCategory.mutate(formData, {
      onSuccess: () => {
        MySwal.fire({
          title: "Created!",
          text: "The meal category has been created.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          queryClient.invalidateQueries([MEAL_CATEGORIES_QUERY_KEY]);
        });
      },
      onError: () => {
        MySwal.fire({
          title: "Not Created!",
          text: "Failed to create meal category.",
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    try {
      showCreateConfirmation(() => handleCreateConfirm(data));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Name"
                        register={register("name")}
                        errors={errors.name}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Slug"
                        register={register("slug")}
                        errors={errors.slug}
                      />
                    </div>
                    <div className="mb-3">
                      <SelectInput
                        label="Status"
                        options={categoryStatuses}
                        register={register("statusId", {
                          setValueAs: (v) => parseInt(v, 10),
                        })}
                        errors={errors.categoryId}
                      />
                    </div>

                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Image</span>
                        <FileUploadInput
                          register={register}
                          previewURLs={previewURLs}
                          setPreviewURLs={setPreviewURLs}
                          errors={errors.images}
                          maxFiles={1}
                        />
                      </div>
                    </div>

                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="success" label="Create Category" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddMealCategoryList;
