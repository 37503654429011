import React from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useLocation } from "react-router-dom";

const ProductDetail = () => {
  const location = useLocation();
  const { product } = location.state || {};

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Product Details</h4>
              <h6>Full details of a product</h6>
            </div>
          </div>
          {/* /add */}
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="bar-code-view">
                    <ImageWithBasePath
                      src="assets/img/barcode/barcode1.png"
                      alt="barcode"
                    />
                    <a className="printimg">
                      <ImageWithBasePath
                        src="assets/img/icons/printer.svg"
                        alt="print"
                      />
                    </a>
                  </div>
                  <div className="productdetails">
                    <ul className="product-bar">
                      <li>
                        <h4>Product</h4>
                        <h6>{product.name}</h6>
                      </li>
                      <li>
                        <h4>Description</h4>
                        <h6>{product.description}</h6>
                      </li>
                      <li>
                        <h4>Category</h4>
                        <h6>{product.category}</h6>
                      </li>
                      <li>
                        <h4>Price</h4>
                        <h6>{product.unitPrice}</h6>
                      </li>
                      <li>
                        <h4>Supplier</h4>
                        <h6>{product.supplier}</h6>
                      </li>
                      <li>
                        <h4>Quantity</h4>
                        <h6>{product.quantityOnHand}</h6>
                      </li>
                      <li>
                        <h4>Reorder Level</h4>
                        <h6>{product.reorderLevel}</h6>
                      </li>
                      <li>
                        <h4>Quantity</h4>
                        <h6>{product.category}</h6>
                      </li>
                      <li>
                        <h4>Date</h4>
                        <h6></h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="slider-product-details">
                    <div className="owl-carousel owl-theme product-slide">
                      <div className="slider-product">
                        <ImageWithBasePath
                          alt=""
                          src={product.imageUrl}
                          type="R"
                        />
                        <h4>{product.name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
