import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Breadcrumbs from "../../core/breadcrumbs";
import useDeleteExpense from "./../../core/hooks/useDeleteExpense";
import useGetExpense from "../../core/hooks/useGetExpense";
import EditExpense from "../../core/modals/inventory/editexpense";
import AddExpense from "../../core/modals/inventory/addexpense";
import useAuth from "../../core/hooks/useAuth";
import useGetExpenseStatuses from "../../core/hooks/useGetExpenseStatuses";
import { arePermissionsAllowed } from "../../Router/permissionChecker";

const ExpensesList = () => {
  const { auth } = useAuth();

  const userPermissions = auth.permissions || {};

  const isUpdateAllowed = arePermissionsAllowed(
    { HOME: ["READ"] },
    userPermissions
  );

  const isDeleteAllowed = arePermissionsAllowed(
    { HOME: ["READ"] },
    userPermissions
  );

  const [currentRecord, setCurrentRecord] = useState();
  const [expenses, setExpenses] = useState([]);
  const deleteExpense = useDeleteExpense();

  const { data: expenseData, isSuccess: isExpensesSuccess } = useGetExpense();
  const { data: statusData, isSuccess: isStatusesSuccess } =
    useGetExpenseStatuses();

  useEffect(() => {
    if (isExpensesSuccess && isStatusesSuccess && expenseData && statusData) {
      const statusLookup = statusData.data.statuses.reduce((acc, status) => {
        acc[status.id] = status.name;
        return acc;
      }, {});
      setExpenses(
        expenseData.data.expenses.map((expense) => {
          return { ...expense, status: statusLookup[expense.statusId] };
        })
      );
    }
  }, [expenseData, isExpensesSuccess, isStatusesSuccess, statusData]);

  const MySwal = withReactContent(Swal);

  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deleteExpense.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The meal category has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          });
        },
        onError: () => {
          MySwal.fire({
            title: "Not Deleted!",
            text: "Failed to delete meal category.",
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const actionColumn =
    isUpdateAllowed || isDeleteAllowed
      ? {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          render: (text, record) => (
            <div className="action-table-data">
              <div className="edit-delete-action">
                {isUpdateAllowed && record.status != "Approved" && (
                  <Link
                    className="me-2 p-2 mb-0"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                    onClick={() => setCurrentRecord(record)}
                  >
                    <i data-feather="edit" className="feather-edit"></i>
                  </Link>
                )}
                {isDeleteAllowed && record.status != "Approved" && (
                  <Link
                    className="me-3 confirm-text p-2 mb-0"
                    to="#"
                    onClick={() =>
                      showDeleteConfirmationAlert(() =>
                        handleDeleteConfirm(record.id)
                      )
                    }
                  >
                    <i data-feather="trash-2" className="feather-trash-2"></i>
                  </Link>
                )}
              </div>
            </div>
          ),
        }
      : {};

  const baseColumn = [
    {
      title: "Expense for",
      dataIndex: "expenseFor",
      sorter: (a, b) => a.expenseFor.length - b.expenseFor.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badge ${
            text === "Approved" ? "badge-linesuccess" : "badge-linedanger"
          }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
  ];

  const columns = [...baseColumn, actionColumn];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const [searchText, setSearchText] = useState("");

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Breadcrumbs
            maintitle="Expense List "
            subtitle="Manage Your Expense"
            addButton="Add New Expense"
          />
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={expenses} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* Add Expense */}
      <AddExpense />
      {/* /Add Expense */}

      {/* Edit Expense */}
      <EditExpense currentRecord={currentRecord} />
      {/* /Edit Expense */}
    </div>
  );
};

export default ExpensesList;
