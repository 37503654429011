import React from "react";
import PropTypes from "prop-types";
const CheckboxInput = ({ register, id, label, icon, errors }) => {
  return (
    <>
      <span className="status-label">{label}</span>
      <input
        {...register}
        type="checkbox"
        id={id}
        className="check"
        defaultChecked="true"
      />
      {icon ? icon : <label htmlFor={id} className="checktoggle" />}
      {errors && <p className="text-danger">{errors.message}</p>}
    </>
  );
};

CheckboxInput.propTypes = {
  register: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
  errors: PropTypes.string,
};

export default CheckboxInput;
