import React from "react";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import TextInput from "../../form-components/TextInput";
import SelectInput from "../../form-components/SelectInput";
import TextAreaInput from "./../../form-components/TextAreaInput";
import useAddExpense from "../../hooks/useAddProductCategory";
import Button from "../../form-components/Button";
import useAuth from "./../../hooks/useAuth";
import useGetExpenseCategories from "./../../hooks/useGetExpenseCategories";
// import { all_routes } from "./../../../Router/all_routes";
// import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { EXPENSES_QUERY_KEY } from "./../../queries/queryKeys";

const schema = z.object({
  categoryId: z.number().min(1, { message: "Category cannot be empty" }),
  amount: z.number().min(1, { message: "Amount cannot be empty" }),
  expenseFor: z.string().min(1, { message: "Expense for cannot be empty" }),
  description: z
    .string()
    .min(1, { message: "Description for cannot be empty" }),
});

const AddExpense = () => {
  const addExpense = useAddExpense();
  const queryClient = useQueryClient();
  // const nagivation = useNavigate();
  const { data: expenseCategoryData } = useGetExpenseCategories();

  const categoryLookup =
    expenseCategoryData?.data?.expenses?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const MySwal = withReactContent(Swal);

  const { auth } = useAuth();

  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this expense?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });

    formData.append("userId", auth.userId);

    addExpense.mutate(formData, {
      onSuccess: () => {
        MySwal.fire({
          title: "Created!",
          text: "The expense has been created.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          queryClient.invalidateQueries([EXPENSES_QUERY_KEY]);
        });
      },
      onError: () => {
        MySwal.fire({
          title: "Not Created!",
          text: "Failed to create expense.",
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    try {
      showCreateConfirmation(() => handleCreateConfirm(data));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Expense For"
                        register={register("expenseFor")}
                        errors={errors.expenseFor}
                      />
                    </div>
                    <div className="mb-3">
                      <TextAreaInput
                        label="Description"
                        register={register("description")}
                        errors={errors.description}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Amount"
                        register={register("amount", {
                          setValueAs: (v) => parseInt(v, 10),
                        })}
                        errors={errors.amount}
                      />
                    </div>
                    <div className="mb-0">
                      <SelectInput
                        label="Category"
                        options={categoryLookup}
                        register={register("categoryId", {
                          setValueAs: (v) => parseInt(v, 10),
                        })}
                        errors={errors.categoryId}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="info" label="Create Category" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddExpense;
