import React from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Table from "../../core/pagination/datatable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SupplierModal from "../../core/modals/peoples/supplierModal";
import useGetSuppliers from "../../core/hooks/useGetSuppliers";
import useDeleteSupplier from "../../core/hooks/useDeleteSupplier";
import useAuth from "../../core/hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { SUPPLIERS_QUERY_KEY } from "../../core/queries/queryKeys";
const Suppliers = () => {
  const queryClient = useQueryClient();
  const { data: supplierData, isSuccess } = useGetSuppliers();
  const deleteSupplier = useDeleteSupplier();
  const MySwal = withReactContent(Swal);

  const { auth } = useAuth();

  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deleteSupplier.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The supplier has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries(SUPPLIERS_QUERY_KEY);
          });
        },
        onError: () => {
          MySwal.fire({
            title: "Not Deleted!",
            text: "Failed to delete supplier.",
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "name",
      render: (text, record) => (
        <span className="productimgname">
          <Link to="#" className="product-img stock-img">
            <ImageWithBasePath alt="" src={record.profileImgUrl} type="R" />
          </Link>
          <Link to="#">{text}</Link>
        </span>
      ),
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },

    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() =>
                showDeleteConfirmationAlert(() =>
                  handleDeleteConfirm(record.id)
                )
              }
            ></Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Supplier List "
          subtitle="Manage Your Supplier"
          addButton="Add New Supplier"
        />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              {isSuccess && (
                <Table
                  className="table datanew"
                  columns={columns}
                  dataSource={supplierData.data.suppliers}
                  rowKey={(record) => record.id}
                />
              )}
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      <SupplierModal />
    </div>
  );
};

export default Suppliers;
