import React from "react";
import PropTypes from "prop-types";
export default function Button({ color, label, margin, onClick, ...rest }) {
  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
  };
  return (
    <button
      className={`btn btn-${color} ${margin}`}
      onClick={handleClick}
      {...rest}
    >
      {label}
    </button>
  );
}

Button.propTypes = {
  margin: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
