import React, { useEffect } from "react";
import PropTypes from "prop-types";
const Modal = ({
  modalType = "modal-default",
  modalId,
  children,
  role = "dialog",
  modalSize = "",
  show = false,
  ...props
}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "0px";
    } else {
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }
  }, [show]);

  return (
    <>
      {show && (
        <div
          className={`modal fade ${modalType} ${show ? "show" : ""}`}
          id={modalId}
          aria-labelledby={modalId}
          style={{ display: show ? "block" : "hidden" }}
          tabIndex={-1}
          aria-modal="true"
          {...props}
        >
          <div
            className={`modal-dialog ${modalSize} modal-dialog-centered`}
            role={role}
          >
            <div className="modal-content">{children}</div>
          </div>
        </div>
      )}
      {show && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

Modal.propTypes = {
  modalType: PropTypes.string,
  modalId: PropTypes.string,
  role: PropTypes.string,
  modalSize: PropTypes.string,
  handleHideModal: PropTypes.func,
  show: PropTypes.bool,
  children: PropTypes.node,
};

export default Modal;
