import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

// Create a context for the modal
const ModalContext = createContext();

// Provide context to children components
export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState({});

  const showModal = (modalId, modalData = {}) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalId]: { visible: true, data: modalData },
    }));
  };

  const hideModal = (modalId) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalId]: { ...prevModals[modalId], visible: false },
    }));
  };

  return (
    <ModalContext.Provider value={{ modals, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use the modal context
export const useModal = () => useContext(ModalContext);
