import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

import getEnvVariable from "../../getEnvVariable";
import { SUPPLIERS_QUERY_KEY } from "../queries/queryKeys";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetSuppliers = () => {
  const { getAll } = usePrivateAPIClient(baseUrl, "/api/v1/suppliers", true);
  return useQuery({
    queryKey: [SUPPLIERS_QUERY_KEY],
    queryFn: () => getAll(),
  });
};

export default useGetSuppliers;
